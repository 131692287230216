import React from 'react';
import { classes } from './WidgetRoot.st.css';
import { GroupsWidgetWithStyles } from '../../../Groups/Widget/App/types';
import { GroupsAppProvider } from '../../../Groups/contexts/GroupsAppProvider';
import { GroupsSection } from '../../../Groups/Widget/Groups/GroupsSection';
import { useExperiments } from '@wix/yoshi-flow-editor';
import { ThemeProvider } from '../../../../common/context/theme';
import { useSettings, useStyles } from '@wix/tpa-settings/react';
import { settingsParams } from 'common/settings/groups-list/settings';
import { stylesParams } from 'common/settings/groups-list/styles';

// TODO: refactor for GroupWidgetWithStyles interface
export const WidgetRoot: React.FC<GroupsWidgetWithStyles> = (props) => {
  const { experiments } = useExperiments();
  const settings = useSettings();
  const styles = useStyles();
  return (
    <div className={classes.root}>
      <GroupsAppProvider {...props}>
        <ThemeProvider
          value={{
            buttonType: settings.get(settingsParams.buttonType),
            buttonBorderRadius: styles.get(stylesParams.buttonBorderRadius),
          }}
        >
          <GroupsSection />
        </ThemeProvider>
      </GroupsAppProvider>
    </div>
  );
};

WidgetRoot.displayName = 'WidgetRoot';
